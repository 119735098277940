import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout.js';
import Markdown from 'markdown-to-jsx';

import { checkLocation, cn } from '../../utils.js';
import { CommonContext } from '../../context.js';
import { AchievementCard } from '../../components/AchievementsSection.js';
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronDown,
  PolygonPrimary,
  QuestionMarkCircle,
} from '../../custom-icons/index.js';

const ImpactList = ({ impacts }) => {
  return (
    <div className="grid w-full grid-cols-1 gap-4 my-8 md:gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {impacts.map((item) => (
        <AchievementCard
          key={item.title}
          title={item.title}
          icon={item.icon}
          description={item.description}
        />
      ))}
    </div>
  );
};

ImpactList.propTypes = {
  impacts: PropTypes.array.isRequired,
};

const DonationLoadingState = () => (
  <div className=" bg-neutral-0">
    <div className="cursor-progress animate-pulse">
      <div className="flex items-center p-4 border-b border-b-neutral-2">
        <span className="text-xl md:text-3xl">Choose amount</span>
      </div>
      <div id="donor-form-step-1">
        <div className="flex items-center gap-2 mx-auto mt-8 md:gap-4 w-min">
          <div className="px-6 py-4 border rounded-md md:px-10 md:py-6 whitespace-nowrap text-secondary border-secondary">
            One-time
          </div>
          <div className="px-6 py-4 rounded-md md:px-10 md:py-6 whitespace-nowrap bg-secondary text-neutral">
            Monthly
          </div>
        </div>

        <div className="mt-6">
          <div className="flex justify-between border-b border-b-neutral-2">
            <div>British Pound Sterling (GBP)</div>
            <ChevronDown className="text-neutral-7" />
          </div>
        </div>
        <div className="flex w-full gap-3 mt-4">
          <div className="flex-1 px-6 py-4 text-xl text-center border rounded-md border-neutral-5">
            <span>£</span>
            <span>10</span>
          </div>
          <div className="flex-1 px-6 py-4 text-xl text-center border rounded-md border-neutral-5">
            <span>£</span>
            <span>25</span>
          </div>
          <div className="flex-1 px-6 py-4 text-xl text-center border rounded-md border-neutral-5">
            <span>£</span>
            <span>100</span>
          </div>
        </div>
        <div className="flex-1 px-6 py-4 mt-4 border rounded-md border-neutral-5">
          <span className="ml-1 text-neutral-5">£</span>
          <span className="ml-1 text-neutral-5">Custom Amount</span>
        </div>
        <div className="mt-4">
          <label className="text-neutral-7">
            <input
              type="checkbox"
              name="leave_a_comment"
              id="leave_a_comment"
            />
            <span className="ml-1">Write us a comment</span>
          </label>
          <img
            alt="Gift Aid"
            src="https://donorbox.org/assets/gift_aid-3b0304178fcc1c1593d3d8d636ece3acd8d15323bc9004713cf307752a2ef788.png"
            width="100"
            className="mt-4"
          />
          <p className="mt-4">Boost your donations by 25% at no cost to you!</p>
        </div>
        <button
          className="flex justify-center w-full gap-2 py-4 my-6 rounded-md bg-secondary text-neutral "
          disabled
        >
          <p> Next </p>
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  </div>
);

const QuestionsList = ({ questions }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  return (
    <div className="p-6 shadow-lg bg-neutral-0 rounded-2xl">
      <div className="flex gap-2 mb-4 leading-6 font-semiBold">
        <QuestionMarkCircle className="w-6 h-6 text-neutral-5" />
        <p> Questions?</p>
      </div>
      <div className="p-0 text-sm leading-6 font-regular markdown">
        {questions.map((item) => {
          return (
            <React.Fragment key={item.title}>
              <div
                className="mb-4 cursor-pointer"
                onClick={() => {
                  if (item.title === expandedQuestion)
                    setExpandedQuestion(null);
                  else setExpandedQuestion(item.title);
                }}
              >
                <PolygonPrimary
                  className={cn(
                    'inline-block mr-3 w-3 h-3',
                    item.title === expandedQuestion
                      ? 'transform rotate-90'
                      : '',
                  )}
                />
                {item.title}
              </div>
              {item.title === expandedQuestion ? (
                <div className="p-4 pt-1 pb-1 mt-2 mb-4 rounded-2xl bg-neutral-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

QuestionsList.propTypes = {
  questions: PropTypes.array.isRequired,
};

export const query = graphql`
  query DonateDataQuery {
    allWpFaq(
      filter: {
        faq: { category: { eq: "Donation" }, showondonatepage: { eq: true } }
      }
    ) {
      nodes {
        title
        content
        faq {
          category
        }
      }
    }
    allDonate {
      nodes {
        legal_info
        donate_heading
        donate_heading_2
        donate_text
        impacts {
          icon
          title
          description
        }
      }
    }
  }
`;
const DonatePage = ({ location, data }) => {
  const donationQa = data.allWpFaq.nodes;
  data = data.allDonate.nodes[0];

  const [isDonorboxLoading, setIsDonorboxLoading] = useState(true);
  const [donorboxUrl, setDonorboxUrl] = useState('');

  const generateDonorboxUrl = (currency) => {
    const baseUrl =
      'https://donorbox.org/embed/greentechapps?hide_donation_meter=true';
    const currencyParam = currency ? `&currency=${currency.toLowerCase()}` : '';
    const searchParams = location.search.replace('?', '&');
    return `${baseUrl}${currencyParam}${searchParams}`;
  };

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const location = checkLocation();

        const currencyCode = location.currency_code || 'GBP';
        localStorage.setItem('currency', currencyCode);

        const newDonorboxUrl = generateDonorboxUrl(currencyCode);
        setDonorboxUrl(newDonorboxUrl);
      } catch (error) {
        const newDonorboxUrl = generateDonorboxUrl();
        setDonorboxUrl(newDonorboxUrl);
      }
    };

    const currency = localStorage.getItem('currency');
    if (currency) {
      const newDonorboxUrl = generateDonorboxUrl(currency);
      setDonorboxUrl(newDonorboxUrl);
    } else {
      fetchLocationData();
    }

    return () => {
      setIsDonorboxLoading(true);
      setDonorboxUrl('');
    };
  }, []);

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        title="Donate"
        keywords={['donate', 'donation', 'islam', 'help', 'fund']}
        description={data.donate_text}
        location={location}
      >
        <section className="w-full">
          <div className="w-full bg-neutral-1">
            <div className="w-full max-w-6xl px-4 pt-6 mx-auto sm:px-6 sm:pt-10">
              <div className="p-4 px-4 shadow-lg bg-neutral-0 rounded-2xl md:p-8 sm:px-6 sm:pt-10 sm:pb-6">
                <div className="px-4 text-xl text-center md:text-3xl md:px-12">
                  <Markdown>{data.donate_heading}</Markdown>
                  <p className="mt-1 font-semiBold text-primary">
                    {data.donate_heading_2}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full max-w-6xl px-4 pt-6 pb-6 mx-auto lg:grid lg:grid-cols-7 lg:gap-4 sm:px-6">
              <div className="p-4 mb-6 shadow-lg bg-neutral-0 rounded-2xl sm:p-6 lg:p-8 lg:col-span-5">
                <div className="text-center">
                  {isDonorboxLoading && <DonationLoadingState />}
                  {donorboxUrl && (
                    <iframe
                      className={`${isDonorboxLoading ? 'hidden' : 'block'} `}
                      // eslint-disable-next-line
                      allowpaymentrequest=""
                      frameBorder="0"
                      name="donorbox"
                      scrolling="no"
                      seamless="seamless"
                      src={donorboxUrl}
                      style={{
                        maxHeight: 'none!important',
                      }}
                      width="100%"
                      onLoad={() => {
                        setIsDonorboxLoading(false);
                      }}
                    />
                  )}
                  <div className="flex flex-col justify-start gap-3 p-3 text-xs lg:gap-6 lg:flex-row bg-primary-2 rounded-xl text-neutral-7">
                    <div className="flex items-center w-max">
                      <CheckIcon className="text-primary-4" />
                      <p>Quarterly & Annual transparency reports</p>
                    </div>
                    <div className="flex items-center w-max">
                      <CheckIcon className="text-primary-4" />
                      <p>Cancel / Edit anytime</p>
                    </div>
                    <div className="flex items-center w-max">
                      <CheckIcon className="text-primary-4" />
                      <p>Giftaid (UK only)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 lg:col-span-2">
                <QuestionsList questions={donationQa} />
              </div>
            </div>
            <div className="w-full">
              <div className="w-full max-w-6xl px-6 py-6 m-auto md:py-20">
                <h3 className="text-3xl text-center">Impact of Your Support</h3>
                <ImpactList impacts={data.impacts} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </CommonContext.Provider>
  );
};

DonatePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default DonatePage;
